import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Navigation from "../components/navigation"
import SEO from "../components/seo"
import PropTypes from "prop-types"

/**
 * @param {Object} data Translations
 */
const StartPage = ({
  data: {
    prismic: { allFooters, allKeywordss, allStartpages },
  },
}) => {
  const {
    meta_title,
    meta_description,
    meta_keywords,
    meta_img,
  } = allStartpages.edges[0].node
  return (
    <Layout
      keywords={allKeywordss}
      footer={allFooters}
      navigation={
        <Navigation
          title={allStartpages.edges[0].node.title}
          items={allStartpages.edges[0].node.body[0].fields}
        />
      }
    >
      <SEO
        meta={{
          title:
            meta_title && meta_title.length > 0 ? meta_title[0].text : null,
          description:
            meta_description && meta_description.length > 0
              ? meta_description[0].text
              : null,
          keywords:
            meta_keywords && meta_keywords.length > 0
              ? meta_keywords[0].text
              : null,
          img: !!meta_img ? meta_img.url : null,
          url: window.location.pathname,
        }}
      />
    </Layout>
  )
}
StartPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StartPage

export const pageQuery = graphql`
  query($locale: String!) {
    prismic {
      allStartpages(lang: $locale) {
        edges {
          node {
            title
            latest
            news
            text
            meta_title
            meta_description
            meta_keywords
            meta_img
            body {
              ... on PRISMIC_StartpageBodyMenu {
                fields {
                  url_text
                  url
                }
              }
            }
          }
        }
      }
      allKeywordss(lang: $locale) {
        edges {
          node {
            glossary
            news
            aboutus
            integritypolicy
            privacypolicy
            disclaimer
            resources
            contact
            about
          }
        }
      }
      allFooters(lang: $locale) {
        edges {
          node {
            about
            aboutus
            resources
            news
            dictionary
            legal
            disclaimer
            privacypolicy
            whatwedo
            whatwedotext
            responsiblegaming
            responsiblegamingtext
            contact
            contacttext
            bottomlinks
          }
        }
      }
    }
  }
`
